<template>
  <div class="d-flex h-100 flex-column">
    <TabHeader :header="$t('js.sidebar.headers.amendments')" />
    <div class="flex-grow-1 justify-content-end p-3 overflow-auto bg-blur-dark pretty-scroll-theme" style="height: 1px;" id="chat" @scroll="checkScroll">
      <div v-if="amendments.length < 1" class="text-white-50 font-italic text-center">
        {{ $t('js.sidebar.amendments.no_amendments') }}
      </div>
      <Amendment v-for="item in amendments" :item="item" :key="`amendment_${item.id}`"/>
    </div>
    <div class="p-3 text-white bg-blur-darker">
      <button class="btn btn-theme" @click.prevent="showNewForm = true" v-if="!showNewForm"><i class="fas fa-plus"></i>
        {{$t('js.sidebar.amendments.propose_amendment')}}</button>
      <form @submit.prevent="validateForm()" v-if="showNewForm">
        <button type="button" class="btn btn-link float-end text-white" @click.prevent="showNewForm = false" ><i class="fas fa-times"></i></button>
        <h4>{{$t('js.sidebar.amendments.propose_amendment')}}</h4>
        <div v-if="responseStatus !== 200 && responseErrors" class="bg-theme-danger text-white p-3 my-2">
          <b>{{ $t('js.form.please_correct_the_following')}}</b>
          <ul class="mb-0">
            <li v-for="(errors, propertyName ) in responseErrors">
              <span v-for="error in errors">
                {{ $t(`js.forms.candidacy.errors.${error.error}` , {property: $t(`js.properties.candidacy.${propertyName}`)}) }}
              </span>
            </li>
          </ul>
        </div>
        <div class="mb-3">
          <label for="associated_ballot_amendment">{{$t('js.sidebar.amendments.associated_ballot')}}</label>
          <select id="associated_ballot_amendment" class="form-control" v-model="target_slide_id" required>
            <option v-for="slide in ballotSlides" :value="slide.id" :selected="slide.id === target_slide_id">{{ slide.title[firstAvailableLocale]}}</option>
          </select>
        </div>
        <label for="description_of_amendment">{{$t('js.sidebar.amendments.description_of_amendment')}}</label>
        <div class="input-group mb-3">
          <textarea id="description_of_amendment" v-model="message" type="text" class="form-control" rows="3" :placeholder="$t('js.sidebar.amendments.description_of_amendment_placeholder')" required></textarea>
        </div>
        <button class="btn btn-theme" @click.prevent="validateForm()" :disabled="submitting"><i class="fas fa-paper-plane"></i>
          {{$t('js.sidebar.amendments.send_amendment')}}</button>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
  import TabHeader from "./TabHeader.vue"
  import Amendment from "../../../shared/posts/Amendment.vue"

  export default {
    name: 'Amendments',
    components: {Amendment, TabHeader},
    data(){
      return {
        showNewForm: false,
        target_slide_id: null,
        message: "",
        responseMessage: "",
        responseErrors: null,
        responseStatus: null,
        scrolled: false,
        submitting: false
      }
    },
    computed: {
      ...mapState(['election', 'voting', 'posts', 'slides', 'postsUrl', 'chatUser']),
      ...mapGetters(['activeSlide', 'firstAvailableLocale']),
      amendments(){
        return this.posts.filter(p => p.type === 'Amendment')
      },
      ballotSlides(){
        return this.slides.filter(s => s.type === 'Slides::BallotSlide')
      }
    },
    watch: {
      posts: function(posts){
        if(!this.scrolled){
          this.$nextTick(function () {
            let d = $('#chat');
            d.scrollTop(d.prop("scrollHeight"));
          })
        }
      },
    },
    methods: {
      ...mapMutations(['markAllPostsRead']),
      ...mapActions(['updateStatus', 'submitPost']),
      validateForm(){
        if(this.submitting) return;
        this.submitting = true
        this.submitPost({
          post: {
            type: 'Posts::Amendment',
            message: this.message,
            target_slide_id: this.target_slide_id
          }
        }).then(res => {
          if(res.status === 200){
            this.message = ""
            this.showNewForm = false
          }
          this.responseMessage = res.data.message
          this.responseStatus = res.status
          this.$store.commit('showToast', {header: this.$t('js.toasts.created.amendment'), body: '', duration: 5, classes: {header: 'bg-success text-white'}})
        }).catch( error => {
          this.responseStatus = error.response.status
          this.responseErrors = error.response.data.errors
        }).finally(()=>{
          this.submitting = false
        })
      },
      checkScroll(){
        let d = $('#chat');
        this.scrolled = (d.scrollTop() ) < (d.prop("scrollHeight") - d.prop("offsetHeight"))
      }
    },
    mounted(){
      if(this.activeSlide){
        this.target_slide_id = this.activeSlide.id
      }

      this.$nextTick(function () {
        let d = $('#chat');
        d.scrollTop(d.prop("scrollHeight"));
      })
    }
  }
</script>
