<template>
  <div id="slide-body" v-if="activeSlide" class="d-flex flex-column">
    <div class="flex-grow-1 w-100 position-relative">
      <transition name="slide" :enter-from-class="slideOffset < 0 ? 'slide-right-position' : 'slide-left-position'"
                  :leave-to-class="slideOffset < 0 ?'slide-left-position':'slide-right-position'">
          <Slide :slide="activeSlide" :active="true" :showHeader="showHeader" :key="activeSlide.id"/>
      </transition>
    </div>
  </div>
  <div v-else class="col d-flex flex-grow-1 justify-content-center align-items-center">
    <div class="text-center text-white p-2">
      <h3>{{ $t('js.slide_show.no_slide_active') }}</h3>
      <p>{{ $t('js.slide_show.presentation_not_started') }}</p>
    </div>
  </div>
</template>
<script>
    import Slide from './Slide.vue'
    import {mapState, mapGetters} from "vuex"
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: 'SlideShow',
        components: {Slide},
        data(){
          return {
            slideOffset: 0,
          }
        },
      props: {
        showHeader: {
            type: Boolean,
            default: true
          }
      },
        computed: {
            ...mapState(['slides']),
            ...mapGetters(['activeSlide']),
        },
        watch:{
            activeSlide: function(newVal, oldVal){
              if(newVal === undefined || newVal === null) return;

              this.slideOffset = oldVal ? oldVal.position - newVal.position : newVal.position;
                if(oldVal === undefined || oldVal === null || oldVal.id === newVal.id) return
                let slideBody = $('#slide-body')
                slideBody.stop(true, false).animate({scrollTop:0}, 1000);

                slideBody.on("mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function(){
                    slideBody.stop(true, false);
                });
            }
        }
    });
</script>
