<template>
  <main v-if="!accessToDraft" class="vh-100 d-flex flex-grow-1 slideshow-background" :style="backgroundStyle">
    <section class="h-100 d-flex flex-grow-1 pretty-scroll justify-content-center align-items-center">
      <div class="col d-flex flex-column flex-grow-1" style="max-width: 600px;">
        <div class="d-flex flex-wrap justify-content-between rounded-top p-3 bg-theme">
          <h3 class="mb-0">{{election.title[firstAvailableLocale]}}</h3>
          <div class="d-flex gap-1">
            <button class="btn btn-sm btn-danger" @click="destroySession(false)">{{ $t('js.actions.sign_out') }}</button>
            <LocaleSwitcher />
          </div>
        </div>
        <div class="p-3 rounded-bottom bg-white">
          <p class="lead mb-0" v-html="$t('js.election.not_yet_started_html')"></p>
        </div>
      </div>
    </section>
  </main>
  <main v-else class="mobile-voting-layout slideshow-background" :style="backgroundStyle">
    <transition name="fade" mode="out-in">
      <div v-show="navVisible" class="mobile-side-bar bg-dark">
        <div>
          <div class="d-flex">
            <MSideBarButton class="text-white py-2" @click="navVisible = !navVisible"
                            :icon="'fa-times'" :color="'btn-link'" :text="'Menu'"/>
          </div>
          <MSideBarButton @click="showTab('Presentation')" :isActive="visibleTab === 'Presentation'"
                          :text="$t('js.sidebar.headers.presentation')" :icon="'fa-images'"/>
          <MSideBarButton v-if="userModules.comments" @click="showTab('Comment')" :isActive="visibleTab === 'Comment'" :unread="unreadComments"
                          :text="$t('js.sidebar.headers.comments')" :icon="'fa-comment'"/>
          <MSideBarButton v-if="userModules.amendments" @click="showTab('Amendment')" :isActive="visibleTab === 'Amendment'" :unread="unreadAmendments"
                          :text="$t('js.sidebar.headers.amendments')" :icon="'fa-file-signature'"/>
          <MSideBarButton v-if="userModules.candidacies" @click="showTab('Candidacy')" :isActive="visibleTab === 'Candidacy'" :unread="unreadCandidacies"
                          :text="$t('js.sidebar.headers.candidacies')" :icon="'fa-user'"/>
          <MSideBarButton v-if="latestConfig && accessToDraft" @click="showTab('VotingRounds')" :isActive="votingRoundsOpen"
                          :text="$t('js.sidebar.headers.voting_rounds')" :icon="'fa-vote-yea'"/>
          <MSideBarButton data-bs-toggle="modal" data-bs-target="#highlightModal" :isActive="activeHighlight != null" :color="activeHighlight ? 'btn-success' : 'btn-secondary'"
                          :text="$t('js.sidebar.headers.highlight')" :icon="'fa-thumbtack'" :pulse="activeHighlight"/>
        </div>
        <div>
          <VoterIdentifier :voter="currentVoter"/>
          <VoterCounts class="px-5" />
          <MSideBarButton v-if="availableLocales.length > 1" @click="showTab('Locales')"
                          :text="$t('js.sidebar.headers.locales')" :icon="'fa-flag'" :color="'btn-secondary'"/>
          <MSideBarButton @click="destroySession(false)"
                          :text="$t('js.actions.sign_out')" :icon="'fa-door-open'" :color="'btn-theme-danger'"/>
        </div>
      </div>
    </transition>
    <ToastManager placement="bottom center" style="grid-area: content; z-index: 5"></ToastManager>
    <div class="mobile-side-bar-main-content pretty-scroll" v-show="presentationOpen">
      <div class="bg-theme p-3" style="grid-area: header; display: grid; grid-template-columns: minmax(0, 1fr) auto;">
        <h3 class="mb-0">{{election.title[firstAvailableLocale]}}</h3>
        <button class="btn btn-toned d-lg-none text-contrast" type="button" @click.prevent="showNav">
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <Stream v-if="streamOpen && election.streamUrl" style="grid-area: stream"/>
      <SlideShow :showHeader="false" style="grid-area: slideshow"></SlideShow>
    </div>
    <Comments v-if="commentsOpen" @showNav="navVisible = !navVisible" style="grid-area: content"></Comments>
    <Amendments v-else-if="amendmentsOpen" @showNav="navVisible = !navVisible" style="grid-area: content"></Amendments>
    <Candidacies v-else-if="candidacyOpen" @showNav="navVisible = !navVisible" style="grid-area: content"></Candidacies>
    <LocaleTab v-else-if="localesOpen" @showNav="navVisible = !navVisible" style="grid-area: content"></LocaleTab>
    <VotingRounds v-else-if="votingRoundsOpen" @showNav="navVisible = !navVisible" style="grid-area: content"></VotingRounds>
    <div v-show="navVisible" @click="navVisible = !navVisible" style="position: fixed; top: 0; bottom: 0; left: 0; right:0;" class="bg-blur-dark">
      <!-- this is a cover up -->
    </div>
    <HighlightModal></HighlightModal>
    <ElectionClientModal v-if="accessToDraft"></ElectionClientModal>
  </main>
</template>

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
  import Comments from "../sidebar/Comments.vue";
  import Amendments from "../sidebar/Amendments.vue";
  import Candidacies from "../sidebar/Candidacies.vue";
  import LocaleTab from "../../../shared/LocaleTab.vue";
  import SlideShow from "../SlideShow.vue";
  import MSideBarButton from "../sidebar/MSideBarButton.vue";
  import VoterCounts from "../../../shared/VoterCounts.vue";
  import TabHeader from "../sidebar/TabHeader.vue";
  import Stream from "../Stream.vue";
  import HighlightModal from "../../shared/HighlightModal.vue"
  import LocaleSwitcher from "../../shared/LocaleSwitcher.vue"
  import ToastManager from "../../../shared/toasts/ToastManager.vue";
  import VoterIdentifier from "./VoterIdentifier.vue";
  import ElectionClientModal from '../../shared/VotingRoundModal.vue';
  import VotingRounds from '../../../shared/VotingRounds.vue';

  export default {
    name: 'MobileLayout',
    components: {
      VotingRounds,
      ElectionClientModal,
      ToastManager,
      VoterIdentifier,
      LocaleSwitcher,
      HighlightModal,
      Stream,
      TabHeader,
      VoterCounts, MSideBarButton, SlideShow, LocaleTab, Candidacies, Amendments, Comments},
    props: {
      election: {
        type: Object,
        required: true
      }
    },
    data:() => ({
      navVisible: false
    }),
    computed: {
      ...mapGetters(['activeSlide', 'firstAvailableLocale']),
      ...mapState(['visibleTab', 'activeHighlight', 'posts', "latestConfig"]),
      ...mapState("voting", ["currentVoter"]),
      backgroundStyle(){
        if (this.activeSlide && this.accessToDraft) {
          if(this.activeSlide.background) {
            return {
              backgroundImage: `url('${this.activeSlide.background}')`,
              backgroundPosition: 'center fixed',
              backgroundSize: 'cover'
            };
          } else {
            return {
              backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5AcGEhcPXNSe4QAAABdJREFUGNNj/PHzFwMpgImBRDCqgSYaABKAAv2s97RbAAAAAElFTkSuQmCC')",
              backgroundPosition: 'center fixed',
              backgroundSize: 'cover'
            }
          }
        } else {
          return {
            backgroundColor: 'rgba(0,0,0,0.50)'
          }
        }
      },
      userModules(){
        return this.election.userModules
      },
      availableLocales(){
        return this.$i18n.availableLocales;
      },
      commentsOpen(){
        return this.userModules.comments && this.visibleTab === 'Comment'
      },
      amendmentsOpen(){
        return this.userModules.amendments && this.visibleTab === 'Amendment'
      },
      candidacyOpen(){
        return this.userModules.candidacies && this.visibleTab === 'Candidacy'
      },
      localesOpen(){
        return this.visibleTab === 'Locales'
      },
      presentationOpen(){
        return this.visibleTab === 'Presentation'
      },
      votingRoundsOpen(){
        return this.visibleTab === 'VotingRounds'
      },
      streamOpen(){
        return !!this.activeSlide
      },
      unreadComments(){
        if(this.visibleTab === 'Comment') return 0
        return this.posts.filter(p => p.type === 'Comment' && p.unread).length
      },
      unreadAmendments(){
        return this.posts.filter(p => p.type === 'Amendment' && p.unread).length
      },
      unreadCandidacies(){
        return this.posts.filter(p => p.type === 'Candidacy' && p.unread).length
      },
      accessToDraft(){
        return this.election.mode === 'production' || (this.election.mode === 'demo' && this.currentVoter.demo)
      }
    },
    methods: {
      ...mapActions(["fetchLatestConfig"]),
      ...mapActions('voting', ['destroySession']),
      ...mapMutations(['setVisibleTab']),
      showTab(tab){
        this.navVisible = false
        if( tab === this.visibleTab){
          this.setVisibleTab('Presentation')
        } else {
          this.setVisibleTab(tab)
        }
        if(tab === "VotingRounds") {
          this.fetchLatestConfig()
        }
      },
      showNav(){
        this.navVisible = !this.navVisible
      }
    }
  }
</script>