<template>
  <main v-if="!accessToDraft" class="vh-100 d-flex flex-grow-1 slideshow-background" :style="backgroundStyle">
    <section class="h-100 d-flex flex-grow-1 pretty-scroll justify-content-center align-items-center">
      <div></div>
      <div class="col d-flex flex-column flex-grow-1" style="max-width: 600px;">
        <div class="d-flex flex-wrap justify-content-between rounded-top p-4 bg-theme">
          <h3 class="mb-0">{{election.title[firstAvailableLocale]}}</h3>
          <div class="d-flex gap-1">
            <button class="btn btn-sm btn-danger" @click="destroySession(false)">{{ $t('js.actions.sign_out') }}</button>
            <LocaleSwitcher />
          </div>
        </div>
        <div class="p-3 rounded-bottom bg-white">
          <p class="lead mb-0" v-html="$t('js.election.not_yet_started_html')"></p>
        </div>

      </div>
    </section>
  </main>
  <main v-else class="side-bar-layout slideshow-background" :style="backgroundStyle">
    <ToastManager style="z-index: 5; grid-area: presentation" placement="bottom left" class="mb-3 ms-3"/>
    <section class="side-bar-main-content pretty-scroll">
      <div class="side-bar-video">
        <div v-if="streamOpen" class="container" :class="{'d-none d-sm-block': visibleTab !== 'Presentation'}">
          <div class="row">
            <Stream v-if="election.streamUrl" class="mx-0 mx-md-5 w-100"/>
          </div>
        </div>
      </div>
      <SlideShow/>
    </section>
    <transition :name="overlayMode ? 'expand-overlay' : 'expand-push'" mode="out-in">
      <Comments class="side-bar-tab" :class="{overlay: overlayMode}" v-if="commentsOpen" @showNav="navVisible = !navVisible"></Comments>
      <Amendments class="side-bar-tab" :class="{overlay: overlayMode}" v-else-if="amendmentsOpen" @showNav="navVisible = !navVisible"></Amendments>
      <Candidacies class="side-bar-tab" :class="{overlay: overlayMode}" v-else-if="candidacyOpen" @showNav="navVisible = !navVisible"></Candidacies>
      <VotingRounds class="side-bar-tab" :class="{overlay: overlayMode}" v-else-if="latestConfig && votingRoundsOpen" @showNav="navVisible = !navVisible"></VotingRounds>
      <LocaleTab class="side-bar-tab" :class="{overlay: overlayMode}" v-else-if="localesOpen" @showNav="navVisible = !navVisible"></LocaleTab>
    </transition>
    <nav class="side-bar bg-dark">
      <div>
        <SideBarButton v-if="userModules.comments && accessToDraft" @click="showTab('Comment')" :isActive="commentsOpen" :unread="unreadComments"
                       :text="$t('js.sidebar.headers.comments')" :icon="'fa-comment'"/>
        <SideBarButton v-if="userModules.amendments && accessToDraft" @click="showTab('Amendment')" :isActive="amendmentsOpen" :unread="unreadAmendments"
                       :text="$t('js.sidebar.headers.amendments')" :icon="'fa-file-signature'"/>
        <SideBarButton v-if="userModules.candidacies && accessToDraft" @click="showTab('Candidacy')" :isActive="candidacyOpen" :unread="unreadCandidacies"
                       :text="$t('js.sidebar.headers.candidacies')" :icon="'fa-user'"/>
        <SideBarButton v-if="userModules.votingRounds && latestConfig && accessToDraft" @click="showTab('VotingRounds')" :isActive="votingRoundsOpen"
                       :text="$t('js.sidebar.headers.voting_rounds')" :icon="'fa-vote-yea'"/>
        <SideBarButton v-if="accessToDraft" data-bs-toggle="modal" data-bs-target="#highlightModal" :isActive="activeHighlight != null" :color="activeHighlight ? 'btn-success' : 'btn-secondary'"
                       :text="$t('js.sidebar.headers.highlight')" :icon="'fa-thumbtack'" :pulse="activeHighlight"/>
      </div>
      <div>
        <VoterIdentifier :voter="currentVoter"/>
        <VoterCounts v-if="accessToDraft"/>
        <SideBarButton v-if="availableLocales.length > 1" @click="showTab('Locales')" :isActive="localesOpen"
                       :text="$t('js.sidebar.headers.locales')" :icon="'fa-flag'" :color="'btn-secondary'"/>
        <SideBarButton @click="destroySession(false)"
                       :text="$t('js.actions.sign_out')" :icon="'fa-door-open'" :color="'btn-theme-danger'"/>
      </div>
    </nav>
    <HighlightModal v-if="accessToDraft"></HighlightModal>
    <VotingRoundModal v-if="accessToDraft"></VotingRoundModal>
  </main>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import SideBarButton from "../sidebar/SideBarButton.vue";
import Comments from "../sidebar/Comments.vue";
import Amendments from "../sidebar/Amendments.vue";
import Candidacies from "../sidebar/Candidacies.vue";
import LocaleTab from "../../../shared/LocaleTab.vue";
import SlideShow from "../SlideShow.vue";
import VoterCounts from "../../../shared/VoterCounts.vue";
import breakpoint from "../../../../entrypoints/shared/breakpoint";
import Stream from "../Stream.vue";
import HighlightModal from "../../shared/HighlightModal.vue"
import LocaleSwitcher from "../../shared/LocaleSwitcher.vue"
import ToastManager from "../../../shared/toasts/ToastManager.vue";
import VoterIdentifier from "./VoterIdentifier.vue";
import VotingRounds from '../../../shared/VotingRounds.vue';
import VotingRoundModal from '../../shared/VotingRoundModal.vue';

export default {
    name: 'DesktopLayout',
    components: {
      VotingRoundModal,
      ToastManager,
      VoterIdentifier,
      LocaleSwitcher,
      HighlightModal,
      Stream,
      VoterCounts, SlideShow, LocaleTab, Candidacies, Amendments, Comments, SideBarButton,
      VotingRounds,
    },
    props: {
      election: {
        type: Object,
        required: true
      }
    },
    data:() => ({
      navVisible: false,
     }),
    computed: {
      ...mapGetters(['activeSlide', 'firstAvailableLocale']),
      ...mapState(['visibleTab', 'activeHighlight', 'posts', "latestConfig"]),
      ...mapState("voting", ["currentVoter"]),
      overlayMode(){
        return breakpoint.lessEquals('md')
      },
      backgroundStyle(){
        if (this.activeSlide && this.accessToDraft) {
          if(this.activeSlide.background) {
            return {
              backgroundImage: `url('${this.activeSlide.background}')`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            };
          } else {
            return {
              backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5AcGEhcPXNSe4QAAABdJREFUGNNj/PHzFwMpgImBRDCqgSYaABKAAv2s97RbAAAAAElFTkSuQmCC')",
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }
          }
        } else {
          return {
            backgroundColor: 'rgba(0,0,0,0.5)'
          }
        }
      },
      userModules(){
        return this.election.userModules
      },
      availableLocales(){
        return this.$i18n.availableLocales;
      },
      commentsOpen(){
        return this.userModules.comments && this.visibleTab === 'Comment'
      },
      amendmentsOpen(){
        return this.userModules.amendments && this.visibleTab === 'Amendment'
      },
      candidacyOpen(){
        return this.userModules.candidacies && this.visibleTab === 'Candidacy'
      },
      votingRoundsOpen(){
        return this.visibleTab === 'VotingRounds'
      },
      localesOpen(){
        return this.visibleTab === 'Locales'
      },
      presentationOpen(){
        return this.visibleTab === 'Presentation'
      },
      streamOpen(){
        return !!this.activeSlide
      },
      unreadComments(){
        if(this.visibleTab === 'Comment') return 0
        return this.posts.filter(p => p.type === 'Comment' && p.unread).length
      },
      unreadAmendments(){
        return this.posts.filter(p => p.type === 'Amendment' && p.unread).length
      },
      unreadCandidacies(){
        return this.posts.filter(p => p.type === 'Candidacy' && p.unread).length
      },
      accessToDraft(){
        let isProduction = this.election.mode === 'production'
        let isVoterDemo = this.currentVoter.demo
        return isProduction || (!isProduction && isVoterDemo)
      }
    },
    methods: {
      ...mapActions(["fetchLatestConfig"]),
      ...mapActions("voting", ['destroySession']),
      ...mapMutations(['setVisibleTab']),
      showTab(tab){
        this.navVisible = false
        if( tab === this.visibleTab){
          this.setVisibleTab('Presentation')
        } else {
          this.setVisibleTab(tab)
        }
        if(tab === "VotingRounds") {
          this.fetchLatestConfig()
        }
      },
      showNav(){
        this.navVisible = !this.navVisible
      }
    }
  }
</script>
