<template>
  <Modal @close="resetExpireReason" :visible="!!expireReason" :closable="true" >
    <template v-slot:header>
      <h5 class="modal-title" id="loggedOutModalLabel">{{ $t('js.modal.logged_out.header') }}</h5>
    </template>
    <template v-slot:body>
      <div v-html="expireReason ? $t(`js.expire_reasons.${expireReason}`) : $t('js.modal.logged_out.body_html')"></div>
    </template>
  </Modal>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import Modal from "./Modal.vue";
  export default {
    name: "LoggedOutModal",
    components: {Modal},
    computed: {
      ...mapState(['voting']),
      expireReason(){
        return this.voting.expireReason
      }
    },
    methods: {
      ...mapMutations("voting", ['resetExpireReason'])
    }
  }
</script>