<template>
  <MobileLayout :election="election" v-if="election && layout === 'mobile'"/>
  <DesktopLayout :election="election" v-else-if="election && layout === 'desktop'"/>
</template>

<script>
  import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
  import MobileLayout from "./layouts/MobileLayout.vue";
  import breakpoint from "../../../entrypoints/shared/breakpoint";
  import DesktopLayout from "./layouts/DesktopLayout.vue";
  import visibility from "@/lib/visibility";
  import {electionChannelSubscription} from "@/entrypoints/frontend/store";

  export default {
    components: {
      DesktopLayout,
      MobileLayout
    },
    computed: {
      ...mapState(['election', 'voting']),
      ...mapGetters(['activeSlide']),
      visible(){
        return visibility.visible;
      },
      layout() {
        return breakpoint.greaterEquals("sm") ? "desktop" : "mobile";
      }
    },
    watch: {
      visible: function(newState, oldState){
        if(newState){
          this.updateStatus();
          this.updatePosts();
        }
      }
    },
    methods: {
      ...mapActions(['updateStatus', 'updatePosts', 'fetchResult', 'checkWebsocketConnection', "fetchLatestConfig", "subscribeToElectionChannel"]),
      ...mapMutations(['goToSlide', 'setProgress', 'setResult', 'closeBallot', 'resetBallot', 'setCounts', 'setBallotState', 'createItem', 'updateItem', 'deleteItem']),
      ...mapActions("voting", ["prolongSession", "subscribeToVoterChannel"]),
      ...mapMutations("voting", ["updateVoter", "setRedoVote", "destroySession"]),
    },
    created() {
      this.updateStatus()
      this.updatePosts()
      this.fetchLatestConfig()
      this.subscribeToElectionChannel()
      this.subscribeToVoterChannel()
    },
    unmounted() {
      electionChannelSubscription.value.unsubscribe();
    },
    mounted() {
      this.connectiontimer = setInterval(this.checkWebsocketConnection, 1000)
      this.sessionTimer = setInterval(this.prolongSession, 60_000)
    },
    beforeUnmount() {
      clearInterval(this.connectiontimer)
      clearInterval(this.sessionTimer)
    }
  }
</script>
