<template>
  <div class="d-flex h-100 flex-column">
    <TabHeader :header="$t('js.sidebar.headers.voting_rounds')" />

    <div class="flex-grow-1 justify-content-between p-3 overflow-auto bg-blur-dark pretty-scroll-theme" style="height: 1px;"  id="voting_rounds">
      <details open>
        <summary class="text-white-50 mb-3">
          <span class="badge text-bg-info" :class="{
            'float-end': isLtr,
            'float-start': !isLtr,
          }">{{ openRounds.length }}</span>
          <span class="h-5">
            {{ $t("js.voting_rounds.active_rounds") }}
          </span>
        </summary>
        <div v-if='openRounds.length > 0' class="mb-5">
          <VotingRound
            v-for="votingRound in openRounds"
            :votingRound="votingRound"
            :key="`voting_round_${votingRound.reference}`"
            :roundState='calculateState(votingRound)'
            :votedOn='votedOn(votingRound.reference)'
          />
        </div>
        <div v-else class='text-center p-3 mb-3 text-white-50'>{{ $t("js.voting_rounds.no_active_rounds") }}</div>
      </details>
      <details>
        <summary class="text-white-50 mb-3">
          <span class="badge text-bg-success" :class="{
            'float-end': isLtr,
            'float-start': !isLtr,
          }" >{{ finishedRounds.length }}</span>
          <span class="h-5">
            {{ $t("js.voting_rounds.finished_rounds") }}
          </span>
        </summary>
        <template v-if="finishedRounds.length > 0">
          <VotingRound v-for="votingRound in finishedRounds"

                       :votingRound="votingRound"
                       :key="`voting_round_${votingRound.reference}`"
                       :roundState='calculateState(votingRound, fetchVotingRoundReport(votingRound))'
                       :votedOn="votedOn(votingRound.reference)" />
        </template>
        <div v-else class="text-center p-3 text-white-50 mb-3">{{ $t("js.voting_rounds.no_finished_rounds") }}</div>
      </details>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import TabHeader from "../frontend/voting/sidebar/TabHeader.vue"
  import VotingRound from "./VotingRound.vue"
  import { calculateState } from "../../entrypoints/shared/voting_rounds_helper";

  export default {
    name: 'VotingRounds',
    components: {VotingRound, TabHeader},
    computed: {
      ...mapState("voting", ["currentVoter"]),
      ...mapState(["votingRounds", "votingRoundReports", "chatUser", "election", "votingRound"]),
      ...mapGetters(['firstAvailableLocale']),
      closedRounds(){
        return this.visibleVotingRounds.filter(vr => ["closed", "past", "future"].includes(calculateState(vr, this.fetchVotingRoundReport(vr))))
      },
      openRounds(){
        return this.visibleVotingRounds.filter(vr => ["open", "active"].includes(calculateState(vr)))
      },
      finishedRounds(){
        return this.visibleVotingRounds.filter(vr => ["finished"].includes(calculateState(vr, this.fetchVotingRoundReport(vr))))
      },
      isDemoVoter(){
        return this.currentVoter.demo
      },
      visibleVotingRounds(){
        return this.votingRounds.filter(vr => vr.demo === this.isDemoVoter)
      },
      isLtr(){
        return document.getElementsByTagName('html')[0].dir === 'ltr'
      },
    },
    methods: {
      ...mapActions("voting", ["fetchVotedOn"]),
      calculateState,
      fetchVotingRoundReport(vr) {
        return this.votingRoundReports.find(vrr => vrr.votingRoundReference === vr.reference);
      },
      votedOn(votingRoundReference) {
        return this.currentVoter.votedOn.includes(votingRoundReference);
      },
    },
    watch: {
      votingRound(newVotingRound) {
        if(!newVotingRound) this.fetchVotedOn();
      }
    },
    async mounted() {
      await this.fetchVotedOn()
    },
  }
</script>
