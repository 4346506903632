<template>
  <Modal :visible="!!votingRound" maxWidth="50rem" :closable="false">
    <template #header>
      <div class='modal-title text-muted'>
        {{ label(votingRound, $i18n.locale) }}
      </div>
      <button type="button" class="btn-close m-0" @click='closeModal'/>
    </template>
    <template #outerBody>
      <BallotEmbedded v-if="votingRound" class="modal-body p-0" :voting-round="votingRound"/>
    </template>
  </Modal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { calculateState, label } from "../../../entrypoints/shared/voting_rounds_helper";
import Modal from "../voting/modals/Modal.vue";
import {AVClient} from "@aion-dk/js-client";
import BallotEmbedded from "./BallotEmbedded.vue";

export default {
  name: 'VotingRoundModal',
  components: {BallotEmbedded, Modal},
  computed: {
    ...mapState(["votingRound", "election", "voting"]),
    roundFinished(){
      return this.votingRoundState === "finished"
    },
  },
  methods: {
    label,
    ...mapMutations(["setVotingRound"]),
    closeModal(){
      const votingRoundToExpire = this.votingRound;
      const finished = this.roundFinished;

      this.setVotingRound(null);

      if(!finished){
        this.cancelVoting(votingRoundToExpire)
      }
    },
    async cancelVoting(votingRoundToExpire) {
      try {
        const client = new AVClient(this.election.boardUrl, this.election.dbbPublicKey);
        await client.initialize();
        client.generateProofOfElectionCodes(this.voting.electionCodes);
        await client.expireVoterSessions(votingRoundToExpire.reference);
      } catch (e) {
        console.error(e)
      }
    }
  }
};
</script>